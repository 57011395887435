import {ref} from 'vue';
import {setting, settings} from '../../../../resources/js/Tools/settings/settings';

export const useFavouriteItem = () => {

    const loadItems = () => {
        settings.loadSettings([
            'favourited_items',
        ]);
    };

    const savingSettings = ref<boolean>(false);

    const favouriteItem = (item: string) => {
        if (!(setting.value.favourited_items ?? []).includes(item)) {
            setting.value.favourited_items.push(item);
            saveSettings();
        }
    };

    const unfavouriteItem = (item: string) => {
        if ((setting.value.favourited_items ?? []).includes(item)) {
            setting.value.favourited_items.splice(setting.value.favourited_items.indexOf(item), 1);
            saveSettings();
        }
    };

    const saveSettings = () => {
        savingSettings.value = true;
        settings.setValues({
            favourited_items: setting.value.favourited_items,
        }, () => {
            savingSettings.value = false;
        });
    };

    loadItems();

    return {favouriteItem, unfavouriteItem, savingSettings};

};
