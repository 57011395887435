<template>
    <button type="button" v-if="selected" @click="deselect" class="text-pink hover:text-pink-300">
        <i class="fa fa-heart bg-transparent transition-transform "></i>
    </button>
    <button type="button" v-else @click="select" class="hover:text-pink-200" :class="{'text-white': !showAlways, 'text-gray-400': showAlways}">
        <i class="fa fa-heart bg-transparent"></i>
    </button>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import {useFavouriteItem} from '../UseFavouriteItem';
import {setting} from '../../../../../resources/js/Tools/settings/settings';

const {favouriteItem, unfavouriteItem} = useFavouriteItem();

const props = withDefaults(defineProps<{
    item: string,
    showAlways?: boolean
}>(), {
    showAlways: false
});

const deselect = () => {
    unfavouriteItem(props.item);
};

const select = () => {
    favouriteItem(props.item);
};

const selected = computed(() => {
    return (setting.value.favourited_items ?? []).includes(props.item);
});

</script>

<style scoped>

</style>
